header {
    height: 100vh;
    width: 100vw;
}

.header__container {
   min-width: 100vw;
   height: 100%;
    display: flex;
    flex-direction: column;
}

.header-right{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 100%;
    background-color: var(--color-bg);
}

.header-right img {
    object-fit: contain;
    width: 30rem;
}

.header-left{
    width: 100%;
    height: 50%;
    color: var(--color-bg);
    font-size: 1.2rem;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-left span {
    color: var(--color-white);
    font-weight: 800;
}

.header-left h1{
    width: 700px;
    font-weight: 800;
    text-align: left;
}

/*sroll down*/
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/*Tablet Screen*/
@media screen and (max-width: 1024px){
    header {
        height: 100vh;
    }
    .header-left{
        font-size: 1rem;
    }
    .header-left h1{
        width: 600px;
        margin-left: 20px;
        margin-right: 20px;
    }
}

/*Small Device Screen*/
@media screen and (max-width: 600px){
    header {
        height: 100vh;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }
    .header-right img {
        object-fit: contain;
        width: 20rem;
    }
    .header-left{
        font-size: .75rem;
    }
    .header-left h1{
        width: 450px;
        margin-left: 20px;
    }
}


/*Extra Small Device Screen*/
@media screen and (max-width: 285px){
    .header-right img {
        object-fit: contain;
        width: 15rem;
    }
    .header-left{
        font-size: .5rem;
    }
}