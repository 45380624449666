#services {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.services__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
}

.service {
    background: transparent;
    border: 1px solid var(--color-primary);
    height: 275px;
    transition: var(--transition);
}

.service:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.service__head {
    background: transparent;
    border-bottom: 1px solid var(--color-primary);
    padding: 2rem;
    box-shadow: 0 2rem 1rem rbga(0, 0, 0, 0.1);
    transition: var(--transition);
}

.service:hover > .service__head {
    border-bottom: 1px solid var(--color-primary-variant);
}

.service__head h3 {
    color: var(--color-bg);
    font-size: 1rem;
    text-align: center;
}

.service__list {
    padding: 2rem;
}

.service__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.service__list-icon {
    color: var(--color-primary);
    margin-top: 2px;
}

.service__list p {
    font-size: 0.9rem;
    color: var(--color-bg);
}

/*Tablet Screen*/
@media screen and (max-width: 1024px){
    .services__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .service {
        height: auto;
    }
    .service__head h3 {
        font-size: .9rem;
    }
}

/*Small Device Screen*/
@media screen and (max-width: 600px){
    .services__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
}