
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #CBC0AD;
    --color-bg-variant: #4D5057;
    --color-primary: #86A397;
    --color-primary-variant: #4D5057;
    --color-white: #283044;
    --color-light: #E5BE9E;
    --swiper-navigation-color: #CBC0AD;

    --transition: all 400ms ease;;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    text-align: center;
    font-family: 'Archivo', sans-serif;
    background: var(--color-white);
    color: var(--color-white);
    line-height: 1.7;
}


.container {
    width: var(--container-width-lg);
    margin: 0 0;
}

h1, h2, h3, h4, h5 {
    font-weight: 800;
}

h1 {
    font-size: 2.5 rem;
}

section {
    margin-top: 5rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-bg);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom : 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    cursor: pointer;
}

a:hover {
    color: var(--color-bg);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: var(--color-bg);
}

.btn-primary {
    background: transparent;
    color: var(--color-primary);
    border-color: var(--color-primary);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}


/*Tablet Screen*/
@media screen and (max-width: 1024px){
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/*Small Device Screen*/
@media screen and (max-width: 600px){
    .container {
        width: var(--container-width-sm);
    }

    section > h2{
        margin-bottom: 2rem;
    }

    section {
        margin-top: 2rem;
    }
}