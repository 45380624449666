footer {
    background: var(--color-primary);
    padding: 1rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 2rem;
    height: 250px;
}

footer a {
    color: var(--color-bg);
}

.footer__logo {
    font-size: 2rem;
    font-weight: 500;
}

footer img{
    object-fit: contain;
    max-height: 100px
}

.footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.footer__socials a {
    background: var(--color-bg);
    color: var(--color-primary);
    font-size: 1.5rem;
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
    transition: var(--transition);
}

.footer__socials a:hover {
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}

.footer__copyright {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    color: var(--color-bg);
}

/*Small Device Screen*/
@media screen and (max-width: 600px){
    .permalinks {
        display: none;
    }

    .footer__socials {
        margin-bottom: 2.6rem;
    }
}