

#testimonials{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.container.testimonials__container{
    width: 50%;
    padding-bottom: 3.5rem;
    height: 350px;
}

.client__avatar {
    width: 5rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto .75rem;
    border: 0.4rem solid var(--color-primary-variant);
}

.testimonial {
    background: transparent;
    text-align: center;
    padding: 2rem;
    color: var(--color-primary);
    border: 2px solid var(--color-primary);
    user-select: none;
    height: 300px;
}

.client__review {
    color: var(--color-bg);
    font-size: 1.15rem;
    font-weight: 300;
    display: block;
    width: 85%;
    margin: 0.5rem auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
}

.swiper-button-next, .swiper-button-prev{
    color: var(--swiper-navigation-color);
}

/*Tablet Screen*/
@media screen and (max-width: 1024px){
    .container.testimonials__container {
        width: 70%;
    }
    .client__review{
        width: 90%;
        font-size: 1.1rem;
    }
}

/*Small Device Screen*/
@media screen and (max-width: 600px){
    .container.testimonials__container {
        width: var(--container-width-sm);
    }

    .client__review {
        width: var(--container-width-sm);
        font-size: 1rem;
    }
}