#contact {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container.contact__container {
    width: 50%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    margin: 0.25rem 0 0.25rem 0;
    transition: var(--transition);
}

.contact__option:hover {
    background-color: var(--color-white);
    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 2rem;
    margin-bottom: -0.15rem;
}

.contact__option h5 {
    color: var(--color-light);
}

.a-block{
    display: block;
}

.contact-copy{
    font-weight: 500;
    font-family: 'Archivo', sans-serif;
}

/*Form*/
form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    background: transparent;
    border: 1px solid var(--color-primary);
    resize: none;
    color: var(--color-primary);
    font-family: 'Archivo', sans-serif;
}

/*Tablet Screen*/
@media screen and (max-width: 1024px){
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    .contact__option {
        margin: 0;
    }
}

/*Small Device Screen*/
@media screen and (max-width: 600px){
    .container.contact__container {
        width: var(--container-width-sm)
    }
}